import useAxios from "axios-hooks";

import { Match, HIRAgg, DirectionType, HIR, HIRResponse, Player, PhaseType } from "models/match";
import { useEffect, useRef, useState } from "react";

export type Page = {
    limit: number;
    skip: number;
}

export const useMatches = (clubId: string | undefined, page: Page) => {

    return useAxios<Match[]>(`prostats/v1/clubs/${clubId}/matches`);
}

export const useMatch = (matchId: string) => {

    return useAxios<Match>(`prostats/v1/matches/${matchId}`);
}

const createQueryFromQueryObject = (q: {[index: string]: any}) => {
    const query : string[] = [];
    Object.keys(q)
        .filter((k) => q[k] !== undefined)
        .forEach((k) => {
            const v = q[k];
            if (v !== undefined) {
                query.push(`${encodeURIComponent(k)}=${encodeURIComponent(
                    v
                )}`);
            }
        });

    if (query.length > 0) {
        return "?" + query.join("&");
    } else {
        return "";
    }
}

type HIRAggQuery = {
    team_id?: string;
    home?: boolean;
    phases?: boolean;
}

export const useHirAggregation = (matchId: string, q: HIRAggQuery) => {
    
    const query_str = createQueryFromQueryObject(q);
    return useAxios<HIRAgg[]>(`prostats/v1/matches/${matchId}/hirs/agg${query_str}`);
}

type HIRQuery = {
    team_id?: string;
    player?: string;
    direction?: DirectionType;
    phase?: PhaseType;
}

export const useHirs = (matchId: string, q: HIRQuery) => {
    const query_str = createQueryFromQueryObject(q);

    return useAxios<HIR[]>(`prostats/v1/matches/${matchId}/hirs${query_str}`);
}

export const useHir = (hirId: string) => {
    return useAxios<HIRResponse>(`prostats/v1/hir/${hirId}?include_url=true`);
}

type Cache = {
    [key: string]: PlayerSquadMap;
}

type PlayerSquadMap = {
    [key: number]: Player;
}

export const usePlayerSquadMap = (matchId: string, home: boolean): [PlayerSquadMap | undefined, boolean] => {
    const cache = useRef<Cache>({});
    const [squad, setSquad] = useState<PlayerSquadMap>();
    const [loading, setLoading] = useState<boolean>(false);

    const [, loadSquad] = useAxios<Player[]>(`prostats/v1/matches/${matchId}/players?home=${home}`, { manual: true });

    useEffect(() => {

        const fetchData = async () => {
            const key = matchId + ":" + home;
            setLoading(true);
            if (cache.current[key]) {
                setSquad(cache.current[key]);
                setLoading(false);
            } else {
                setLoading(true);
                const squadResponse = await loadSquad();
                const players = squadResponse.data.reduce<PlayerSquadMap>((acc, cur) => {
                    acc[cur.jersey_no] = cur;
                    return acc;
                }, {});
                setSquad(players);
                setLoading(false);
                cache.current[key] = players;
            }
        };

        fetchData();
        
    }, [matchId, home, loadSquad, setLoading, setSquad])

    return [squad, loading];
}

export const usePlayer = (matchId: string, teamId: string, jerseyNo: number | string): [Player | undefined, boolean] => {
    const [{ data, loading }, loadPlayer] = useAxios<Player[]>(`prostats/v1/matches/${matchId}/players?team_id=${teamId}&jersey_no=${jerseyNo}`, { manual: true});

    useEffect(() => {
        if (!(matchId && teamId && jerseyNo)) return;
        loadPlayer();
    }, [loadPlayer, matchId, teamId, jerseyNo])

    return [data && data[0], loading];
}