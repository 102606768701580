import React, { FunctionComponent } from "react";
import { Player as PlayerType } from "models/match";
import { Avatar, makeStyles } from "@material-ui/core";

type PlayerProps = {
    player: PlayerType
}

const useStyles = makeStyles((theme) => ({
    player: {
        display: "flex",
        alignItems: "center",
        "> span": {
            margin: "10px"
        }
    }
}));

const Player : FunctionComponent<PlayerProps> = ({player}) => {
    const classes = useStyles();

    return <div className={classes.player}>
        <Avatar alt={`${player.first_name} ${player.last_name}`} src={player.img} />
        <span>{player.first_name} {player.last_name}</span>
    </div>;
};

export default Player;