import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { Avatar, Box, Button, Card, CardActions, CardHeader, CardMedia, CircularProgress, createStyles, Fab, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Add as AddIcon, Storage as StorageIcon, PlayCircleOutline as PlayCircleOutlineIcon } from "@material-ui/icons";
import deepOrange from "@material-ui/core/colors/deepOrange";

import { useMatches, Page } from "./matchesHook";
import { FormattedMessage, useIntl } from "react-intl";
import useClub from "sharedHooks/useClub";
import { Match, ProcessingStatus } from "models/match";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        cardRoot: {
        },
        cardHeaderRoot: {
            overflow: "hidden"
        },
        cardHeaderContent: {
            overflow: "hidden"
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        avatar: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        fab: {
            position: "fixed",
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        }
    }),
);

const renderResources = (match: Match) => {
    if (!match.status || match.status === ProcessingStatus.PREPARE) {
        return (
            <Button
                variant="contained"
                color="primary"
                startIcon={<StorageIcon />}
                disabled
            >
                Resources <CircularProgress size={15} />
            </Button>
        );
    } if (match.status === ProcessingStatus.PROCESSING) {
        const progress = match.processing_progress ? Math.min(100, Math.round(match.processing_progress * 100)) : 0;
        return (
            <Button
                variant="contained"
                color="primary"
                startIcon={<StorageIcon />}
                disabled
            >
                Resources&nbsp;<CircularProgress size={15} variant="determinate" value={progress} />&nbsp;{progress}%
            </Button>
        );
    } else if (match.status === ProcessingStatus.FINISHED) {
        return (
            <Button
                variant="contained"
                color="primary"
                startIcon={<StorageIcon />}
                component={Link} to={`/matches/${match._id}/resources`}
                disableElevation
            >
                Resources
            </Button>
        );
    }
};

const Matches: FunctionComponent = () => {

    const [club] = useClub();

    const [ page ] = useState<Page>({ limit: 20, skip: 0 });
    const [{ data: matches}, loadMatches] = useMatches(club?._id, page);

    const classes = useStyles();
    const intl = useIntl();

    useEffect(() => {
        loadMatches();
    }, [loadMatches]);


    return (
        <Grid container justify="flex-start" spacing={3}>
            {matches && matches.map((match) => (
                <Grid key={match._id} item xs={4}>
                    <Card className={classes.cardRoot}>
                        <CardHeader
                            classes={{
                                root: classes.cardHeaderRoot,
                                content: classes.cardHeaderContent
                            }}
                            avatar={
                                <Avatar aria-label="field" className={classes.avatar}>
                                    M
                                </Avatar>
                            }
                            title={
                                <Typography noWrap gutterBottom>
                                    {match.team_home.name} - {match.team_away.name}
                                </Typography>
                            }
                            subheader={<span>{intl.formatDate(match.start_time)}</span>}
                        />
                        <CardMedia
                            className={classes.media}
                            image="https://www.stadt-wien.at/typo3temp/pics/x800px-Franz-Horr-Stadion-Panorama_ca1444de2f.jpg.pagespeed.ic.FL5m6_xwO-.jpg"
                            title={`${match.team_home.name} - ${match.team_away.name}`}
                        />
                        <CardActions>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PlayCircleOutlineIcon />}
                                component={Link} to={`/matches/${match._id}`}
                                disableElevation
                            >
                                <FormattedMessage id="matches.view" description="View video message" />
                            </Button>
                            {renderResources(match)}
                        </CardActions>
                    </Card>
                </Grid>
            ))}
            <Fab
                color="primary"
                aria-label="add"
                className={classes.fab}
                component={Link}
                to="/matches/add"
            >
                <AddIcon />
            </Fab>
        </Grid>
    );
};

export default Matches;
