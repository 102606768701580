export type DirectionType =  "backwards" | "forwards" | "sidewards";
export type PhaseType =  "defensive_play" | "offensive_play" | "transition_defense" | "transition_offense";

export const isPhaseType = (obj: any): obj is PhaseType => {
    return ["defensive_play", "offensive_play", "transition_defense", "transition_offense"].includes(obj);
};

export type Direction = {
    direction: DirectionType;
    total: number;
    total_distance: number;
}

export type Phase = {
    phase: PhaseType;
    total: number;
    total_distance: number;
}

export type HIR = {
    _id: string;
    player: number;
    team_id: string;
    match_id: string;
    time: number;
    duration: number;
    distance: number;
    speed_min: number;
    speed_avg: number;
    speed_max: number;
    zone_start: number;
    zone_end: number;
    points: number[][];
    direction: string;
    direction_detail: number;
    left_to_right: boolean;
    phase: string;
}

export interface HIRResponse extends HIR {
    // video_url: string;
    // video_from: number;
    // video_to: number;
}

export type HIRAgg = {
    player: number;
    team_id: string;
    directions?: Direction[];
    phases?: Phase[]
    total: number;
    total_distance: number;
};

export type Club = {
    _id: string,
    name: string,
    color: string,
    belongs_to?: string
};

export type CreatableClub = {
    name: string,
    color: string,
    belongs_to: string
}

export enum ProcessingStatus {
    PREPARE = "prepare",
    PROCESSING = "processing",
    FINISHED = "finished",
    ERROR = "error"
}
export interface Match {
    _id: string,
    team_home: Club,
    team_away: Club,
    start_time: Date,
    status?: ProcessingStatus,
    processing_progress?: number
}

export interface MatchResources {
    spaces_video: string
}

export interface CreatableMatch {
    club_home_id: string,
    club_away_id: string,
    start_time: Date,
    pitch_width: number,
    pitch_height: number,
    paint_hir: boolean,
    hir_threshold?: number,
    paint_speed: boolean,
    season: Season,
    file: File
}

export interface Squad {
    _id: string;
    club_id: string;
    season: string;
    players: Player[];
}

export interface Player {
    first_name: string;
    last_name: string;
    jersey_no: number;
    height?: number;
    pos: string;
    img: string;
}

export interface MatchDetail extends Match {
    players: string[]
};

export enum Season {
    S19_20 = "19_20",
    S20_21 = "20_21",
    S21_22 = "21_22"
}