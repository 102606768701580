import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ColorPicker } from 'material-ui-color';
import { FormattedMessage } from "react-intl";
import {
    Box,
    Button,
    Dialog,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import { CreatableClub } from "models/match";
import useClub from "sharedHooks/useClub";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            position: "relative",
        },
        form: {},
        submit: {},
    })
);

type AddClubModalProps = {
    initialClub?: string;
    open?: boolean;
    onSubmit?: (values: CreatableClub) => void;
    onClose?: () => void;
}

const AddClubModal : FunctionComponent<AddClubModalProps> = ({ open = false, initialClub, onSubmit, onClose}) => {
    const { handleSubmit, register, formState: { errors }, control, setValue } = useForm();

    const [club] = useClub();

    const classes = useStyles();

    const [ color, setColor ] = useState<string>("white");

    const onInternalSubmit = useCallback((values: any) => {
        if (!club) return;

        onSubmit?.({
            name: values.name,
            color,
            belongs_to: club._id
        });
    }, [club, color, onSubmit]);

    useEffect(() => {
        if (!initialClub) return;
        setValue("name", initialClub || "");
    }, [initialClub, setValue]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            >
                <Box p={2}>
                    <form 
                        className={classes.form}
                        noValidate
                        onSubmit={(e) => {
                            handleSubmit(onInternalSubmit)(e);
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography align="left" variant="h4"><FormattedMessage id="team.add.title" /></Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue={initialClub || ""}
                                    rules={{ required: true }}
                                    render={(field) => <TextField 
                                        label={<FormattedMessage id="team.add.name" />}
                                        variant="outlined"
                                        fullWidth
                                        error={Boolean(errors?.club)}
                                        helperText={
                                            errors.club?.type === "required" ? <FormattedMessage id="matches.add.errors.required" /> : undefined
                                        }
                                        {...field} />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <ColorPicker value={color} defaultValue="white" hideTextfield onChange={(color) => {
                                    setColor(`#${color.hex}`);
                                }}/>
                            </Grid>
                            <Grid item  xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    <FormattedMessage id="team.add.save" />
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
        </Dialog>
        
    );
}

export default AddClubModal;