import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, createStyles } from "@material-ui/core/styles";
import {
    Drawer,
    IconButton,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from "@material-ui/core";
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    SupervisedUserCircleOutlined,
    Home,
} from "@material-ui/icons";
import useMenuOpen from "sharedHooks/useMenuOpen";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
}));

const Sidebar: FunctionComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, toggleMenuOpen] = useMenuOpen();

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            // 
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            // 
        >
            <div className={classes.toolbar}>
                <IconButton onClick={toggleMenuOpen}>
                    {theme.direction === "rtl" ? (
                        <ChevronRightIcon />
                    ) : (
                        <ChevronLeftIcon />
                    )}
                </IconButton>
            </div>
            <Divider />
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary={"Home"} />
                </ListItem>
                <ListItem button component={Link} to="/matches">
                    <ListItemIcon>
                        <SupervisedUserCircleOutlined />
                    </ListItemIcon>
                    <ListItemText primary={"Matches"} />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default Sidebar;
