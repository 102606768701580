import { useEffect } from "react";
import useAxios from "axios-hooks";
import { Club, MatchResources } from "models/match";
import { AxiosError } from "axios";

type UseMatchResourcesHook = (matchId: string | undefined) => [ MatchResources | undefined, boolean, AxiosError<any> | undefined, () => void]

export const useMatchResources : UseMatchResourcesHook = (clubId: string | undefined) => {

    const [{data, loading, error}, loadMathResources] = useAxios<MatchResources>(`prostats/v1/matches/${clubId}/resources`);

    useEffect(() => {
        loadMathResources();
    }, [loadMathResources]);

    return [data, loading, error, loadMathResources];
}