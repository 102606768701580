import React, {
    FunctionComponent,
    RefObject,
    useEffect,
    useRef,
} from "react";
import * as d3 from "d3";

import { HIRAgg, DirectionType, Player, PhaseType } from "models/match";
import { round } from "sharedFunctions/utils";

const drawChart = (
    svgRef: RefObject<SVGElement>,
    aggHirs: HIRAgg[],
    onChartSegmentClicked?: (player: number, segment: DirectionType | PhaseType) => void,
    playerMap?: PlayerJerseyMap,
    width = 840,
    height = 544,
    margin = { top: 40, right: 40, bottom: 40, left: 40 },
) => {
    console.log("drawing", playerMap);
    if (!svgRef.current) return;

    const widthChart = width - margin.left - margin.right;
    const heightChart = height - margin.top - margin.bottom;

    const svg = d3
        .select(svgRef.current)
        .attr("viewBox", `0 0 ${width} ${height + 100}`);
        //.attr("width", width)
        //.attr("heigt", height);

    d3.select("#hirs-chart").remove();

    const hirs = svg
        .append("g")
        .attr("id", "hirs-chart")
        .attr("transform", `translate(${margin.left},${margin.right})`);
   

    const usePhases = !!aggHirs[0].phases;

    let totalMax = 0;
    const stackedData = aggHirs.map(h => {
        const res = {
            player: h.player,
            totalDistance: 0,
            sidewards: 0,
            backwards: 0,
            forwards: 0,
            offensive_play: 0,
            transition_offense: 0,
            defensive_play: 0,
            transition_defense: 0,
        }

        let playerMax = 0;
        if (h.directions) {
            h.directions.forEach(dir => {
                if (!res[dir.direction])
                res[dir.direction] = dir.total_distance
                playerMax += dir.total_distance
            });
        }
        if (h.phases) {
            h.phases.forEach(dir => {
                res[dir.phase] = dir.total_distance
                playerMax += dir.total_distance
            });
        }
        res.totalDistance = playerMax;
        totalMax = Math.max(totalMax, playerMax);


        return res
    })

    const jerseyNoToName = (jerseyNo: number) => playerMap && playerMap[jerseyNo] ? `${playerMap[jerseyNo].first_name} ${playerMap[jerseyNo].last_name}` : jerseyNo + "";

    const x = d3.scaleBand()
        .domain(aggHirs.map(h => jerseyNoToName(h.player)))
        .range([0, widthChart])
        .padding(0.2)

    hirs.append("g")
        .attr("transform", "translate(0," + heightChart + ")")
        .call(d3.axisBottom(x).tickSizeOuter(0))
        .selectAll("text")  
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");
  
    // Add Y axis
    const y = d3.scaleLinear()
      .domain([0, totalMax])
      .range([ heightChart , margin.bottom ]);

    hirs.append("g")
      .call(d3.axisLeft(y));

    let color: d3.ScaleOrdinal<string, string, never>
    if (usePhases) {
        color = d3.scaleOrdinal<string>()
            .domain(['defensive_play', 'transition_defense', 'offensive_play', 'transition_offense'])
            .range(['#e41a1c','#96080b', '#19e350', '#009629'])
    } else {
        color = d3.scaleOrdinal<string>()
            .domain(['sidewards', 'backwards', 'forwards'])
            .range(['#e41a1c','#377eb8','#4daf4a'])
    }

    const ord = usePhases ? ['offensive_play', 'transition_offense', 'defensive_play', 'transition_defense'] : ["backwards", "forwards", "sidewards"];
    const s = d3.stack()
        .keys(ord)(stackedData)

    var tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

   hirs.append("g")
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(s)
        .enter()
        .append("g")
        .attr("fill", d => color(d.key))
        .selectAll("rect")
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data(d => d)
        .enter()
        .append("rect")
            .on("click", function (this, e, d) {
                var group : any = d3.select(this.parentElement).datum();
                let direction;
                let phaseOrDirection = group.key;
                
                var player = d.data.player;
                
                onChartSegmentClicked && onChartSegmentClicked(player, phaseOrDirection);

            })
            .on("mouseover", function (this, e, d) {
                const group : any = d3.select(this.parentElement).datum();
                const direction = group.key;
                const player = d.data.player
                var hirDistance = d.data[group.key];

                d3.select(this)
                    .attr("stroke", "black")
                    .attr("stroke-width", 2)
                    .attr("cursor", "pointer");

                tooltip.transition().duration(200).style("opacity", 0.9);
                tooltip
                    .html(
                        `${jerseyNoToName(player)}, ${round(hirDistance, 2)}m, ${direction} `
                    )
                    .style("left", e.pageX + "px")
                    .style("top", e.pageY + "px");
            })
            .on("mouseout", function (this, _, d) {
                d3.select(this)
                    .attr("stroke", "none");

                tooltip.transition().duration(500).style("opacity", 0);
            })
            .attr("x", d => x(jerseyNoToName(d.data.player)) || -1)
            .attr("width", x.bandwidth())
            //.attr("y", d => y(d[1]))
            //.attr("height", d => y(d[0]) - y(d[1]))
            .attr("y",  d => y(d[0]))
            .attr("height", 0)
                .transition()
                .duration(d => (d[1] - d[0]) / d.data.totalDistance * 500)
                .ease(d3.easeLinear)
                .delay(function(d, i) {
                    // const subgroup = d3.select<any, any>(this.parentElement).datum().key;
                    return i * 150 + d[0] / d.data.totalDistance * 500;
                })
            .attr("y",  d => y(d[1]))
            .attr("height",  d => y(d[0]) - y(d[1]));
          
}

const removeChart = (
    svgRef: RefObject<SVGElement>
) => {
    if (!svgRef.current) return;

    const svg = d3
        .select(svgRef.current)
    
    svg.selectAll("#hirs-chart").remove()
    
    d3.select(".tooltip")
        .remove()
}

type PlayerJerseyMap = {
    [key: number]: Player;
}

type HirChartProps = {
    hirs: HIRAgg[];
    onChartSegmentClicked?: (player: number, segment: DirectionType | PhaseType) => void;
    playerMap?: PlayerJerseyMap;
};



// const mapLabelToDirection = (label: string) : DirectionType | undefined => {
//     switch (label) {
//         case "Forward": return "forwards";
//         case "Backward": return "backwards";
//         case "Side": return "sidewards";
//         default: return undefined;
//     }
// }

const HirChart: FunctionComponent<HirChartProps> = ({hirs, onChartSegmentClicked, playerMap}) => {
    const chartRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!hirs) return;
        if (!onChartSegmentClicked) return;
        
        drawChart(chartRef, hirs, onChartSegmentClicked, playerMap);

        return () => {
            removeChart(chartRef);
        }
    }, [chartRef, hirs, onChartSegmentClicked, playerMap]);

    return <svg ref={chartRef} />;
};

export default HirChart;
