import React, { FunctionComponent } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Grid, CircularProgress } from "@material-ui/core";

// 
const Splash: FunctionComponent = (props) => {
    const [ keycloak ] = useKeycloak();

    return (
        <div>
            {keycloak.authenticated ? (
                props.children
            ) : (
                <Grid container justify="center" alignItems="center" style={{height: "100vh"}}>
                    <Grid item>
                        <CircularProgress size="5rem" />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};
// 

export default Splash;
