import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, AutocompleteRenderInputParams, createFilterOptions } from "@material-ui/lab";
import { Club } from "models/match";
import { useIntl } from "react-intl";

type ClubOption = {
    name: string;
    club?: Club;
    new?: boolean;
};

type ClubAutocompleteProps = {
    value: Club | undefined;
    clubs: Club[] | undefined;
    loading?: boolean;
    disabled?: boolean;
    renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
    onChange?: (team: Club | null) => void;
    onNewTeamCreate?: (name: string) => void;
};

const filter = createFilterOptions<ClubOption>();

const ClubAutocomplete: FunctionComponent<ClubAutocompleteProps> = ({
    value: controlledValue,
    clubs,
    renderInput,
    disabled,
    onChange,
    onNewTeamCreate,
}) => {
    const intl = useIntl();

    const newValueRef = useRef<string>();

    const [value, setValue] = useState<ClubOption | null>(null);

    useEffect(() => {
        if (!controlledValue) return;

        setValue({
            name: controlledValue.name,
            club: controlledValue,
            new: false
        })
    }, [controlledValue, setValue]);

    const clubOptions = useMemo(
        () =>
            clubs &&
            clubs.map<ClubOption>((club) => {
                const name = `${club.name}`;
                
                return {
                    name,
                    club: club,
                    new: false
                };
            }),
        [clubs]
    );

    return (
        <Autocomplete
            value={value}
            options={clubOptions || []}
            getOptionLabel={(option) => option.name}
            disabled={disabled}
            freeSolo
            renderInput={renderInput}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                    newValueRef.current = params.inputValue;
                    filtered.push({
                        name: `Add "${params.inputValue}"`,
                        new: true,
                    });
                }

                return filtered;
            }}
            onChange={(_event, newVal) => {
                if (typeof newVal === "string") {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                        onNewTeamCreate?.(newValueRef.current || "");
                    });
                    setValue({
                        name: intl.formatMessage(
                            {
                                id: "awayTeamAutocomplete.addNew",
                            },
                            {
                                addableValue: newVal,
                            }
                        ),
                        new: true
                    });
                } else if (newVal?.new) {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                        onNewTeamCreate?.(newValueRef.current || "");
                    });
                    setValue(newVal);
                } else {
                    onChange?.(newVal && newVal.club ? newVal.club : null);
                    setValue(newVal);
                }
            }}
        />
    );
};

export default ClubAutocomplete;
