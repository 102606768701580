import React, { FunctionComponent, MouseEvent, useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    makeStyles,
    createStyles,
    Theme,
    Hidden,
    Button,
    NoSsr,
    Menu,
    MenuItem,
    Tooltip,
} from "@material-ui/core";
import {
    Menu as MenuIcon,
    Translate as TranslateIcon,
    ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import useMenuOpen from "sharedHooks/useMenuOpen";
import ClubMenu from "./ClubMenu";
import { useKeycloak } from "@react-keycloak/web";
import useLocale, { Locale, LOCALE_LABELS } from "sharedHooks/useLocale";
import { FormattedMessage, useIntl } from "react-intl";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: "none",
        },
        name: {
            marginLeft: "12px",
        },
        grow: {
            flex: "1 1 auto",
        },
        language: {
            margin: theme.spacing(0, 0.5, 0, 1),
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "block",
            },
        },
    })
);

const Header: FunctionComponent = () => {
    const classes = useStyles();
    const [open, toggleMenuOpen] = useMenuOpen();
    const [locale, setLocale] = useLocale();
    const [localeMenu, setLocaleMenu] = useState<
        | null
        | (EventTarget & HTMLAnchorElement)
        | (EventTarget & HTMLButtonElement)
    >(null);

    const [keycloak] = useKeycloak();
    const intl = useIntl();

    const handleLocaleMenuClick = (
        event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>
    ) => {
        setLocaleMenu(event.currentTarget);
    };

    const handleLocaleMenuClose = () => {
        setLocaleMenu(null);
    };

    const handleLocaleChange = (
        event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>
    ) => {
        const { chosenLocale } = event.currentTarget.dataset;
        setLocale(chosenLocale as Locale);
        setLocaleMenu(null);
    };

    const logout = () => {
        keycloak.logout();
    };

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleMenuOpen}
                    className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                    })}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6">
                    <img src="/logo/logo_white.png" alt="zone14 ProStats" />
                    <span className={classes.name}>ProStats</span>
                </Typography>
                <div className={classes.grow} />
                <Hidden xsDown>
                    <ClubMenu />
                    <Tooltip
                        title={<FormattedMessage id="toolbar.changeLanguage" />}
                        enterDelay={300}
                    >
                        <Button
                            color="inherit"
                            aria-owns={localeMenu ? "locale-menu" : undefined}
                            aria-haspopup="true"
                            aria-label={intl.formatMessage({
                                id: "toolbar.changeLanguage",
                            })}
                            onClick={handleLocaleMenuClick}
                        >
                            <TranslateIcon />
                            <span className={classes.language}>
                                {
                                    LOCALE_LABELS.filter(
                                        (language) => language.code === locale
                                    )[0]?.text
                                }
                            </span>
                            <ExpandMoreIcon fontSize="small" />
                        </Button>
                    </Tooltip>
                    <NoSsr defer>
                        <Menu
                            id="language-menu"
                            anchorEl={localeMenu}
                            open={Boolean(localeMenu)}
                            onClose={handleLocaleMenuClose}
                        >
                            {LOCALE_LABELS.map((localeItem) => (
                                <MenuItem
                                    component="a"
                                    data-no-link="true"
                                    data-chosen-locale={localeItem.code}
                                    key={localeItem.code}
                                    selected={locale === localeItem.code}
                                    onClick={handleLocaleChange}
                                >
                                    {localeItem.text}
                                </MenuItem>
                            ))}
                        </Menu>
                    </NoSsr>
                    <Button color="inherit" onClick={logout}>
                        Logout
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
