import { useCallback, useEffect, useRef, useState } from "react";
import useAxios from "axios-hooks";
import { Club, CreatableClub, CreatableMatch, Match } from "models/match";
import { AxiosError, AxiosPromise } from "axios";

type UseClubsHook = (clubId: string | undefined) => [ Club[] | undefined, boolean, AxiosError<any> | undefined, () => void]

export const useClubs : UseClubsHook = (clubId: string | undefined) => {

    const [{data, loading, error}, loadClubsAxios] = useAxios<Club[]>(`prostats/v1/clubs/${clubId}/custom_clubs`);

    useEffect(() => {
        loadClubsAxios();
    }, [loadClubsAxios]);

    return [data, loading, error, loadClubsAxios];
}

type CreateClubHook = (
    clubId: string | undefined
) => [
    boolean,
    AxiosError<any> | undefined,
    (club: CreatableClub) => AxiosPromise<Club>
];

export const useCreateClub: CreateClubHook = (clubId: string | undefined) => {
    const [
        { loading: postLoading, error: postError },
        executePost,
    ] = useAxios<Club>(
        {
            url: `prostats/v1/clubs/${clubId}/custom_clubs`,
            method: "POST",
        },
        { manual: true }
    );

    const createEvent = useCallback((club: CreatableClub) => {
        return executePost({
            data: club,
        });
    }, [executePost]);

    return [postLoading, postError, createEvent];
};

type SaveMatchHook = (clubId?: string) => [
    boolean,
    AxiosError<any> | undefined,
    (match: CreatableMatch) => AxiosPromise<Match>
];

// club_home_id: string,
//     club_away_id: string,
//     start_time: Date,
//     pitch_width: number,
//     pitch_height: number,
//     season: Season,
//     file: FileList

export const useSaveMatches: SaveMatchHook = (clubId) => {
    const [
        { loading: putLoading, error: putError },
        executePost,
    ] = useAxios<Match>(
        {
            url: `prostats/v1/clubs/${clubId}/matches`,
            method: "POST",
        },
        { manual: true }
    );


    const saveMatch = (match: CreatableMatch) => {
        const formData = new FormData();
        formData.append("club_home_id", match.club_home_id);
        formData.append("club_away_id", match.club_away_id);
        formData.append("start_time", match.start_time.toISOString());
        formData.append("pitch_width", match.pitch_width.toString());
        formData.append("pitch_height", match.pitch_height.toString());
        formData.append("paint_hir", match.paint_hir ? "1" : "0");
        match.paint_hir && match.hir_threshold && formData.append("hir_threshold", match.hir_threshold.toString());
        formData.append("paint_speed", match.paint_speed ? "1" : "0");
        formData.append("season", match.season);
        formData.append("file", match.file, match.file.name);
        
        return executePost({
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
    };

    return [putLoading, putError, saveMatch];
};