import "./HIROverview.css";
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
    Box,
    Button,
    Card,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useSearchQuery from "sharedHooks/useSearchQuery";
import { DirectionType, PhaseType } from "models/match";
import { useHirs, useMatch, usePlayer } from "../matchesHook";
import ColorPatch from "sharedComponents/ColorPatch/ColorPatch";
import { useIntl } from "react-intl";
import { round } from "sharedFunctions/utils";
import HIRView from "./HIRView";
import PlayerWithClub from "sharedComponents/PlayerWithTeam";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const toDirectionTypeLiteral = (
    str: string | null | unknown
): DirectionType | undefined => {
    switch (str) {
        case "forwards":
            return "forwards";
        case "backwards":
            return "backwards";
        case "sidewards":
            return "sidewards";
        default:
            return undefined;
    }
};

const toPhaseTypeLiteral = (
    str: string | null | unknown
): PhaseType | undefined => {
    switch (str) {
        case "offensive_play":
            return "offensive_play";
        case "transition_offense":
            return "transition_offense";
        case "defensive_play":
            return "defensive_play";
        case "transition_defense":
            return "transition_defense";
        default:
            return undefined;
    }
};

type HIROverview = {
    matchId: string;
    teamId: string;
    player: string;
};

const HIROverview: FunctionComponent = () => {
    const { matchId, teamId, player } = useParams<HIROverview>();

    const [{ data: match }, loadMatch] = useMatch(matchId);
    const { direction: initialDirection, phase: initialPhase } = useSearchQuery(["direction", "phase"]);
    const [direction, setDirection] = useState<DirectionType | undefined>(
        toDirectionTypeLiteral(initialDirection)
    );

    const [phase, setPhase] = useState<PhaseType | undefined>(
        toPhaseTypeLiteral(initialPhase)
    );

    const [{ data: hirs }, loadHirs] = useHirs(matchId, {
        team_id: teamId,
        player,
        direction,
        phase
    });

    const [playerObj] = usePlayer(matchId, teamId, player);

    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();

    useEffect(() => {
        loadMatch();
        loadHirs();
    }, [loadHirs, loadMatch]);

    const handleHirClick = useCallback((hirId: string) => {
        history.push(`/hir/${hirId}`);
    }, [history]);

    const handleDirectionChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const newDirection = event.target.value;
        setDirection(toDirectionTypeLiteral(newDirection));
        history.push(
            `/matches/${matchId}/${teamId}/${player}/hirs?direction=${newDirection}`
        );
    };

    const handlePhaseChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const newPhase = event.target.value;
        setPhase(toPhaseTypeLiteral(newPhase));
        history.push(
            `/matches/${matchId}/${teamId}/${player}/hirs?phase=${newPhase}`
        );
    };

    const teamComponent = match && <>
        {teamId === match.team_home._id
            ? match.team_home.name
            : match.team_away.name}{" "}
        <ColorPatch color={match.team_home.color} />
    </>;

    const playerComponent = playerObj && match ? (
        <PlayerWithClub player={playerObj} club={teamId === match.team_home._id ? match.team_home : match.team_away} />
    ) : (
        <div>
            Spieler {player} | {teamComponent}
        </div>
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box p={1} display="flex" flexDirection="row">
                            <Button
                                variant="contained"
                                component={Link}
                                to={`/matches/${matchId}`}
                                disableElevation
                            >
                                <ArrowBackIcon /> Back
                            </Button>
                            {match && (
                                <Box p={1} textAlign="left">
                                    <Typography variant="h4" align="left">
                                        <span>
                                            {match.team_home.name}{" "}
                                            <ColorPatch
                                                color={match.team_home.color}
                                            />{" "}
                                            - {match.team_away.name}{" "}
                                            <ColorPatch
                                                color={match.team_away.color}
                                            />
                                        </span>
                                        <br />
                                    </Typography>
                                    <span>
                                        {intl.formatDate(match?.start_time)}
                                    </span>
                                    {playerComponent}
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="directions-label"></InputLabel>
                        <Select
                            displayEmpty
                            labelId="directions"
                            value={direction || ""}
                            onChange={handleDirectionChange}
                        >
                            <MenuItem value="">
                                <em>All Directions</em>
                            </MenuItem>
                            <MenuItem value="forwards">Forwards</MenuItem>
                            <MenuItem value="backwards">Backwards</MenuItem>
                            <MenuItem value="sidewards">Sidewards</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="phases-label"></InputLabel>
                        <Select
                            displayEmpty
                            labelId="phases"
                            value={phase || ""}
                            onChange={handlePhaseChange}
                        >
                            <MenuItem value="">
                                <em>All Phases</em>
                            </MenuItem>
                            <MenuItem value="offensive_play">Offensive Play</MenuItem>
                            <MenuItem value="transition_offense">Transition Offense</MenuItem>
                            <MenuItem value="defensive_play">Defensive Play</MenuItem>
                            <MenuItem value="transition_defense">Transition Defense</MenuItem>
                        </Select>
                    </FormControl>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <HIRView
                        hirs={hirs}
                        onHirClick={handleHirClick}
                        tooltipText={(d) => {
                            return (
                                <div>
                                    <span>
                                        Min: {round(d.speed_min * 3.6)} km/h
                                        <br />
                                    </span>
                                    <span>
                                        Avg: {round(d.speed_avg * 3.6)} km/h
                                        <br />
                                    </span>
                                    <span>
                                        Max: {round(d.speed_max * 3.6)} km/h
                                        <br />
                                    </span>
                                    <hr />
                                    <span>Distance: {round(d.distance)} m</span>
                                    <hr />
                                    <span>
                                        Spielminute: {round(d.time / 60, 0)}:
                                        {round(d.time % 60, 0)
                                            .toString()
                                            .padStart(2, "0")}
                                    </span>
                                </div>
                            );
                        }}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};

export default HIROverview;
