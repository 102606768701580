import React, { FunctionComponent, useEffect, useRef } from "react";

export type VideoPlayerPosition = {
    pos: number;
};

type VideoPlayerProps = {
    url: string;
    position?: VideoPlayerPosition;
    from?: number;
    to?: number;
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({url, position, from, to}) => {

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!(position && videoRef.current)) return;

        videoRef.current.currentTime = position.pos;
    }, [videoRef, position])

    return (
        <video
            ref={videoRef}
            crossOrigin="anonymous"
            id="video"
            loop
            controls
        >
            <source src={`${url}#t=${from || ''},${to || ''}`} />
        </video>
    );
};
