import * as d3 from "d3";

const getPitchLines = ({ pitchHeight, pitchWidth }: PitchConfigInternal) => {
    const lines = [];
    // left penalty box
    lines.push({
        x1: 0,
        x2: 16.5,
        y1: pitchHeight / 2 - 11 - 9.15,
        y2: pitchHeight / 2 - 11 - 9.15,
    });
    lines.push({
        x1: 16.5,
        x2: 16.5,
        y1: 13.85,
        y2: pitchHeight / 2 + 11 + 9.15,
    });
    lines.push({
        x1: 0,
        x2: 16.5,
        y1: pitchHeight / 2 + 11 + 9.15,
        y2: pitchHeight / 2 + 11 + 9.15,
    });
    // left six-yard box
    lines.push({
        x1: 0,
        x2: 5.5,
        y1: pitchHeight / 2 - 9.15,
        y2: pitchHeight / 2 - 9.15,
    });
    lines.push({
        x1: 5.5,
        x2: 5.5,
        y1: pitchHeight / 2 - 9.15,
        y2: pitchHeight / 2 + 9.15,
    });
    lines.push({
        x1: 0,
        x2: 5.5,
        y1: pitchHeight / 2 + 9.15,
        y2: pitchHeight / 2 + 9.15,
    });
    // right penalty box
    lines.push({
        x1: pitchWidth - 16.5,
        x2: pitchWidth,
        y1: pitchHeight / 2 - 11 - 9.15,
        y2: pitchHeight / 2 - 11 - 9.15,
    });
    lines.push({
        x1: pitchWidth - 16.5,
        x2: pitchWidth - 16.5,
        y1: pitchHeight / 2 - 11 - 9.15,
        y2: pitchHeight / 2 + 11 + 9.15,
    });
    lines.push({
        x1: pitchWidth - 16.5,
        x2: pitchWidth,
        y1: pitchHeight / 2 + 11 + 9.15,
        y2: pitchHeight / 2 + 11 + 9.15,
    });
    // right six-yard box
    lines.push({
        x1: pitchWidth - 5.5,
        x2: pitchWidth,
        y1: pitchHeight / 2 - 9.15,
        y2: pitchHeight / 2 - 9.15,
    });
    lines.push({
        x1: pitchWidth - 5.5,
        x2: pitchWidth - 5.5,
        y1: pitchHeight / 2 - 9.15,
        y2: pitchHeight / 2 + 9.15,
    });
    lines.push({
        x1: pitchWidth - 5.5,
        x2: pitchWidth,
        y1: pitchHeight / 2 + 9.15,
        y2: pitchHeight / 2 + 9.15,
    });
    // outside borders
    lines.push({ x1: 0, x2: pitchWidth, y1: 0, y2: 0 });
    lines.push({ x1: 0, x2: pitchWidth, y1: pitchHeight, y2: pitchHeight });
    lines.push({ x1: 0, x2: 0, y1: 0, y2: pitchHeight });
    lines.push({ x1: pitchWidth, x2: pitchWidth, y1: 0, y2: pitchHeight });
    // middle line
    lines.push({
        x1: pitchWidth / 2,
        x2: pitchWidth / 2,
        y1: 0,
        y2: pitchHeight,
    });
    // left goal
    lines.push({
        x1: -1.5,
        x2: -1.5,
        y1: pitchHeight / 2 - 7.32 / 2,
        y2: pitchHeight / 2 + 7.32 / 2,
    });
    lines.push({
        x1: -1.5,
        x2: 0,
        y1: pitchHeight / 2 - 7.32 / 2,
        y2: pitchHeight / 2 - 7.32 / 2,
    });
    lines.push({
        x1: -1.5,
        x2: 0,
        y1: pitchHeight / 2 + 7.32 / 2,
        y2: pitchHeight / 2 + 7.32 / 2,
    });
    // right goal
    lines.push({
        x1: pitchWidth + 1.5,
        x2: pitchWidth + 1.5,
        y1: pitchHeight / 2 - 7.32 / 2,
        y2: pitchHeight / 2 + 7.32 / 2,
    });
    lines.push({
        x1: pitchWidth,
        x2: pitchWidth + 1.5,
        y1: pitchHeight / 2 - 7.32 / 2,
        y2: pitchHeight / 2 - 7.32 / 2,
    });
    lines.push({
        x1: pitchWidth,
        x2: pitchWidth + 1.5,
        y1: pitchHeight / 2 + 7.32 / 2,
        y2: pitchHeight / 2 + 7.32 / 2,
    });
    return lines;
};

const getPitchCircles = ({
    pitchHeight,
    pitchWidth,
    lineColor,
}: PitchConfigInternal) => {
    const circles = [];
    // center circle
    circles.push({
        cx: pitchWidth / 2,
        cy: pitchHeight / 2,
        r: 9.15,
        color: "none",
    });
    // left penalty spot
    circles.push({ cx: 11, cy: pitchHeight / 2, r: 0.3, color: lineColor });
    // right penalty spot
    circles.push({
        cx: pitchWidth - 11,
        cy: pitchHeight / 2,
        r: 0.3,
        color: lineColor,
    });
    // kick-off circle
    circles.push({
        cx: pitchWidth / 2,
        cy: pitchHeight / 2,
        r: 0.3,
        color: lineColor,
    });
    return circles;
};

const getArcs = ({
    pitchMultiplier,
    lineWidth,
    pitchWidth,
    pitchHeight,
}: PitchConfigInternal) => {
    const arcs = [];
    const cornerRadius = 1 * pitchMultiplier;
    const penaltyRadius = 9.15 * pitchMultiplier;
    // left top corner
    arcs.push({
        arc: {
            innerRadius: cornerRadius,
            outerRadius: cornerRadius + lineWidth,
            startAngle: (1 / 2) * Math.PI,
            endAngle: Math.PI,
        },
        x: 0,
        y: 0,
    });
    // left bottom corner
    arcs.push({
        arc: {
            innerRadius: cornerRadius,
            outerRadius: cornerRadius + lineWidth,
            startAngle: (1 / 2) * Math.PI,
            endAngle: 0,
        },
        x: 0,
        y: pitchHeight,
    });
    // right top corner
    arcs.push({
        arc: {
            innerRadius: cornerRadius,
            outerRadius: cornerRadius + lineWidth,
            startAngle: (3 / 2) * Math.PI,
            endAngle: Math.PI,
        },
        x: pitchWidth,
        y: 0,
    });
    // right bottom corner
    arcs.push({
        arc: {
            innerRadius: cornerRadius,
            outerRadius: cornerRadius + lineWidth,
            startAngle: 2 * Math.PI,
            endAngle: (3 / 2) * Math.PI,
        },
        x: pitchWidth,
        y: pitchHeight,
    });
    // left penalty arc
    arcs.push({
        arc: {
            innerRadius: penaltyRadius,
            outerRadius: penaltyRadius + lineWidth,
            startAngle: Math.sin(6.5 / 9.15),
            endAngle: Math.PI - Math.sin(6.5 / 9.15),
        },
        x: 11,
        y: pitchHeight / 2,
    });
    // right penalty arc
    arcs.push({
        arc: {
            innerRadius: penaltyRadius,
            outerRadius: penaltyRadius + lineWidth,
            startAngle: -Math.sin(6.5 / 9.15),
            endAngle: -(Math.PI - Math.sin(6.5 / 9.15)),
        },
        x: pitchWidth - 11,
        y: pitchHeight / 2,
    });
    return arcs;
};

type Margin = {
    top: number;
    left: number;
    right: number;
    bottom: number;
};

export interface PitchConfig {
    width: number;
    height: number;
    margin: Margin;
    pitchHeight?: number;
    pitchWidth?: number;
    pitchColor?: string;
    lineWidth?: number;
    lineColor?: string;
    pitchMultiplier?: number;
}

interface PitchConfigInternal extends PitchConfig {
    pitchHeight: number;
    pitchWidth: number;
    pitchColor: string;
    lineWidth: number;
    lineColor: string;
    pitchMultiplier: number;
}

const pitchConfigDefaults = {
    pitchMultiplier: 8,
    pitchColor: "#009A17",
    lineColor: "#fff",
    lineWidth: 1,
    pitchHeight: 68,
    pitchWidth: 105
}

export const drawPitch = (
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
    config: PitchConfig
) => {
    
    const fullConfig : PitchConfigInternal = {
        ...pitchConfigDefaults,
        ...config
    }

    const {
        width,
        height,
        margin,
        pitchColor,
        pitchMultiplier,
        lineColor,
        lineWidth
    } = fullConfig;

    const pitch = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.right})`);

    pitch
        .append("rect")
        .attr("x", -margin.left)
        .attr("y", -margin.top)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .style("fill", pitchColor);

    const pitchLineData = getPitchLines(fullConfig);
    pitch
        .selectAll(".pitchLines")
        .data(pitchLineData)
        .enter()
        .append("line")
        .attr("x1", (d) => d["x1"] * pitchMultiplier)
        .attr("x2", (d) => d["x2"] * pitchMultiplier)
        .attr("y1", (d) => d["y1"] * pitchMultiplier)
        .attr("y2", (d) => d["y2"] * pitchMultiplier)
        .style("stroke-width", lineWidth)
        .style("stroke", lineColor);

    const pitchCircleData = getPitchCircles(fullConfig);
    pitch
        .selectAll(".pitchCircles")
        .data(pitchCircleData)
        .enter()
        .append("circle")
        .attr("cx", (d) => d["cx"] * pitchMultiplier)
        .attr("cy", (d) => d["cy"] * pitchMultiplier)
        .attr("r", (d) => d["r"] * pitchMultiplier)
        .style("stroke-width", lineWidth)
        .style("stroke", lineColor)
        .style("fill", (d) => d["color"]);

    const pitchArcData = getArcs(fullConfig);
    const arc = d3.arc();
    pitch
        .selectAll(".pitchCorners")
        .data(pitchArcData)
        .enter()
        .append("path")
        .attr("d", (d) => arc(d["arc"]))
        .attr(
            "transform",
            (d) =>
                `translate(${pitchMultiplier * d.x},${pitchMultiplier * d.y})`
        )
        .style("fill", lineColor);
};
