import React, { useEffect, useState, useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import {
    Box,
    Button,
    Grid,
    Paper,
    CircularProgress,
    TextField,
    Typography,
    Input,
    FormLabel,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useClub from "sharedHooks/useClub";
import { useClubs, useCreateClub, useSaveMatches } from "./addMatchHooks";
import ClubAutocomplete from "./ClubAutocomplete";
import { Club, CreatableMatch, Season } from "models/match";
import AddClubModal from "./AddClubModal";
import { Label } from "@material-ui/icons";
import LoadingOverlay from "sharedComponents/LoadingOverlay";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            position: "relative",
        },
        form: {},
        submit: {},
        checkboxContainer: {
            marginLeft: "1rem",
            textAlign: "left"
        },
        radioContainer: {
            marginLeft: "2rem",
            textAlign: "left"
        }
    })
);

export default function AddMatch() {
    const { handleSubmit, register, errors, setValue, control } = useForm();

    const history = useHistory();

    const [club] = useClub();

    const [ clubs ] = useClubs(club?._id);

    // const [{ data: otherTeams }] = useClubs({ club_id: club?._id });

    const [saveLoadingClub, , createTeam] = useCreateClub(club?._id);

    const [addModalOpenHome, setAddModalOpenHome] = useState(false);
    const [addModalOpenAway, setAddModalOpenAway] = useState(false);
    const [newClubName, setNewClubName] = useState<string>();

    const awayTeam = useWatch<Club | undefined>({
        control,
        name: 'awayTeam'
    });

    const homeTeam = useWatch<Club | undefined>({
        control,
        name: 'homeTeam'
    });

    const file = useWatch<FileList>({
        control,
        name: 'file'
    });

    const paintHir = useWatch<Boolean>({
        control,
        name: 'paintHir'
    });

    const [saveLoadingMatch,, saveMatch] = useSaveMatches(club?._id);

    const onSubmit = useCallback((values: any) => {
        console.log(values);
        saveMatch({
            start_time: new Date(values.startDate),
            club_home_id: values.homeTeam._id,
            club_away_id: values.awayTeam._id,
            pitch_width: values.pitchWidth,
            pitch_height: values.pitchHeight,
            paint_hir: values.paintHir,
            hir_threshold: values.hirThreshold,
            paint_speed: values.paintSpeed,
            season: values.season,
            file: values.file[0]

        }).then(() => {
            history.push("/matches");
        }).catch(() => {
            // BUGFIX resetting to array values
            setValue("paintSpeed", values.paintSpeed);
            setValue("paintHir", values.paintHir);
        })
    }, [history]);

    useEffect(() => {
        register({ name: 'homeTeam' }, {
            validate: (value) => value || <FormattedMessage id="matches.add.required" />,
        });
        register({ name: 'awayTeam' }, {
            validate: (value) => value || <FormattedMessage id="matches.add.required" />,
        });
        //TODO: register match video selected
    }, [register]);

    const classes = useStyles();

    const initialDateLocalTime = new Date();
    initialDateLocalTime.setMinutes(initialDateLocalTime.getMinutes() - initialDateLocalTime.getTimezoneOffset());


    return (
        <div className={classes.root}>
            <LoadingOverlay loading={saveLoadingMatch}>
                <Paper>
                    <Box p={2}>
                        <Grid item xs={12} md={8}>
                            <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2} alignItems="flex-start">

                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h4"><FormattedMessage id="matches.add.team_home" /></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ClubAutocomplete
                                            value={homeTeam}
                                            disabled={saveLoadingClub}
                                            clubs={clubs}
                                            onChange={(newValue) => {
                                                setValue("homeTeam", newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={<FormattedMessage id="matches.add.team_home" />}
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {saveLoadingClub ? (
                                                                    <CircularProgress
                                                                        color="inherit"
                                                                        size={20}
                                                                    />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                    error={Boolean(errors?.homeTeam)}
                                                    helperText={
                                                        errors.homeTeam?.type === "required" ? <FormattedMessage id="matches.add.errors.required" /> : undefined
                                                    }
                                                />
                                            )}
                                            onNewTeamCreate={(teamName) => {
                                                setAddModalOpenHome(true);
                                                setNewClubName(teamName);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h4"><FormattedMessage id="matches.add.team_away" /></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ClubAutocomplete
                                            value={awayTeam}
                                            disabled={saveLoadingClub}
                                            clubs={clubs}
                                            onChange={(newValue) => {
                                                console.log(newValue);
                                                setValue("awayTeam", newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={<FormattedMessage id="matches.add.team_away" />}
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {saveLoadingClub ? (
                                                                    <CircularProgress
                                                                        color="inherit"
                                                                        size={20}
                                                                    />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                    error={Boolean(errors?.awayTeam)}
                                                    helperText={
                                                        errors.awayTeam?.type === "required" ? <FormattedMessage id="matches.add.errors.required" /> : undefined
                                                    }
                                                />
                                            )}
                                            onNewTeamCreate={(teamName) => {
                                                setAddModalOpenAway(true);
                                                setNewClubName(teamName);
                                            }} />
                                        <AddClubModal
                                            open={addModalOpenHome || addModalOpenAway}
                                            onClose={() => {
                                                setAddModalOpenHome(false);
                                                setAddModalOpenAway(false);
                                                setNewClubName(undefined);
                                            }}
                                            initialClub={newClubName}
                                            onSubmit={async (club) => {
                                                if (!club) return;

                                                
                                                const { data: newTeam } = await createTeam(club);
                                                if (addModalOpenAway) {
                                                    setValue("awayTeam", newTeam);
                                                } else {
                                                    setValue("homeTeam", newTeam);
                                                }
                                                setAddModalOpenHome(false);
                                                setAddModalOpenAway(false);
                                            }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h4"><FormattedMessage id="matches.add.when" /></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="startDate"
                                            id="datetime-local"
                                            label={<FormattedMessage id="matches.add.date" />}
                                            type="datetime-local"
                                            defaultValue={initialDateLocalTime.toISOString().slice(0, 16)}
                                            variant="outlined"
                                            fullWidth
                                            inputRef={register({
                                                required: true
                                            })}
                                            error={Boolean(errors?.startDate)}
                                            helperText={
                                                errors.startDate?.type === "required" ? <FormattedMessage id="matches.add.errors.required" /> : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h4"><FormattedMessage id="matches.add.xy-file-heading" /></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            >
                                                <FormattedMessage id="matches.add.file-with-xy" />
                                                <input 
                                                    name="file"
                                                    id="file" 
                                                    type="file"
                                                    hidden
                                                    ref={register({required: true})}/>
                                        
                                        </Button>
                                        {errors.file && errors.file.type === "required" && <Typography color="error"><FormattedMessage id="matches.add.errors.required" /></Typography>}
                                        {file && file[0]?.name}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h4"><FormattedMessage id="matches.add.spaces-settings" /></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container className={classes.checkboxContainer}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="paintSpeed"
                                                    defaultValue={false}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <FormControlLabel
                                                            control={<Checkbox checked={!!value} onChange={(() => onChange(!value))} />}
                                                            label="Richtung und Geschwindigkeit einzeichnen"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="paintHir"
                                                    defaultValue={true}
                                                    control={control}
                                                    render={({onChange, value}) => (
                                                        <FormControlLabel
                                                            control={<Checkbox checked={!!value} onChange={(() => onChange(!value))} />}
                                                            label="Läufe einzeichnen"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {paintHir && 
                                                <Grid item xs={12} className={classes.radioContainer}>
                                                    <FormControl>
                                                    <Controller
                                                        name="hirThreshold"
                                                        defaultValue="5.5"
                                                        control={control}
                                                        render={({onChange, value}) => (
                                                            <RadioGroup value={value} onChange={onChange}>
                                                                <FormControlLabel
                                                                    value="5.5"
                                                                    label="Läufe > 19.8 km/h"
                                                                    control={<Radio checked={value == "5.5"} />}
                                                                />
                                                                <FormControlLabel
                                                                    value="7"
                                                                    label="Läufe > 25 km/h"
                                                                    control={<Radio checked={value == "7"} />}
                                                                />
                                                            </RadioGroup>
                                                        )}
                                                        />
                                                    </FormControl>
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h4"><FormattedMessage id="matches.add.additional-info" /></Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            name="pitchWidth"
                                            id="pitchWidth"
                                            label={<FormattedMessage id="matches.add.pitch-width" />}
                                            type="number"
                                            defaultValue={105}
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{ min: 90, max: 120 }}
                                            inputRef={register({
                                                required: true,
                                                min: 90,
                                                max: 120
                                            })}
                                            error={Boolean(errors?.pitchWidth)}
                                            helperText={
                                                errors.default_seconds_after?.type === "required" ? <FormattedMessage id="matches.add.errors.required" /> :
                                                errors.default_seconds_after?.type === "min" ? <FormattedMessage id="matches.add.errors.min" values={{minValue: 90}} /> :
                                                errors.default_seconds_after?.type === "max" ? <FormattedMessage id="matches.add.errors.max" values={{maxValue: 120}} /> : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            name="pitchHeight"
                                            id="pitchHeight"
                                            label={<FormattedMessage id="matches.add.pitch-height" />}
                                            type="number"
                                            defaultValue={68}
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{ min: 45, max: 90 }}
                                            inputRef={register({
                                                required: true,
                                                min: 45,
                                                max: 90
                                            })}
                                            error={Boolean(errors?.pitchHeight)}
                                            helperText={
                                                errors.default_seconds_after?.type === "required" ? <FormattedMessage id="matches.add.errors.required" /> :
                                                errors.default_seconds_after?.type === "min" ? <FormattedMessage id="matches.add.errors.min" values={{minValue: 45}} /> :
                                                errors.default_seconds_after?.type === "max" ? <FormattedMessage id="matches.add.errors.max" values={{maxValue: 90}} /> : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Controller
                                            control={control} 
                                            name="season"
                                            rules={{
                                                required: true,
                                                min: 45,
                                                max: 90
                                            }}
                                            defaultValue={Season.S21_22}
                                            render={(props) =>
                                                <TextField
                                                    id="season"
                                                    label={<FormattedMessage id="matches.add.season" />}
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{ min: 45, max: 90 }}
                                                    error={Boolean(errors?.pitchHeight)}
                                                    helperText={
                                                        errors.default_seconds_after?.type === "required" ? <FormattedMessage id="matches.add.errors.required" /> :
                                                        errors.default_seconds_after?.type === "min" ? <FormattedMessage id="matches.add.errors.min" values={{minValue: 45}} /> :
                                                        errors.default_seconds_after?.type === "max" ? <FormattedMessage id="matches.add.errors.max" values={{maxValue: 90}} /> : undefined
                                                    }
                                                    select
                                                    {...props}
                                                >
                                                    {Object.values(Season).map((s) => 
                                                        <MenuItem key={s} value={s}>
                                                            {s}
                                                        </MenuItem>
                                                    )}
                                            </TextField>
                                            } />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            <FormattedMessage id="matches.add.save" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Box>
                </Paper>
            </LoadingOverlay>
        </div>
    );
}
