import React, {
    FunctionComponent,
    ReactElement,
    RefObject,
    useEffect,
    useRef
} from "react";
import * as d3 from "d3";

import { drawPitch } from "./pitch";
import { HIR } from "models/match";
import ReactDOM from "react-dom";

const pitchMultiplier = 8;
const markerBoxWidth = 100,
    markerBoxHeight = 100,
    markerWidth = 10,
    markerHeight = 10;

const draw = (
    svgRef: RefObject<SVGSVGElement>,
    margin = { top: 20, right: 20, bottom: 20, left: 20 },
    pitchWidth = 105,
    pitchHeight = 68
) => {
    if (!svgRef.current) return;

    const svg = d3
        .select(svgRef.current)
        // .attr("width", width + margin.left + margin.right)
        // .attr("height", height + margin.top + margin.bottom);
        .attr("viewBox", `0 0 ${pitchWidth * pitchMultiplier + margin.left + margin.right} ${pitchHeight * pitchMultiplier + margin.top + margin.bottom}`);

    drawPitch(svg, {
        height: pitchHeight * pitchMultiplier,
        width: pitchWidth * pitchMultiplier,
        margin,
        pitchMultiplier,
        pitchHeight,
        pitchWidth
    });

    const defs = svg.append("defs");

    defs.append("marker")
        .attr("id", "arrow")
        .attr("viewBox", `0 0 ${markerBoxWidth} ${markerBoxHeight}`)
        .attr("refX", markerWidth)
        .attr("refY", markerHeight / 2)
        .attr("markerWidth", markerBoxWidth)
        .attr("markerHeight", markerBoxHeight)
        .attr("orient", "auto-start-reverse")
        .append("path")
        .attr("d", (d) =>
            d3.line()([
                [0, 0],
                [0, markerHeight],
                [markerWidth, markerHeight / 2],
            ])
        ) //'M 0,0 m -5,-5 L 5,0 L -5,5 Z')
        .attr("stroke", "black");

    defs.append("marker")
        .attr("id", "arrow-hover")
        .attr("viewBox", `0 0 ${markerBoxWidth} ${markerBoxHeight}`)
        .attr("refX", markerWidth / 2)
        .attr("refY", markerHeight / 4)
        .attr("markerWidth", markerBoxWidth)
        .attr("markerHeight", markerBoxHeight)
        .attr("orient", "auto-start-reverse")
        .attr("fill", "red")
        .append("path")
        .attr("d", (d) =>
            d3.line()([
                [0, 0],
                [0, markerHeight / 2],
                [markerWidth / 2, markerHeight / 4],
            ])
        ) //'M 0,0 m -5,-5 L 5,0 L -5,5 Z')
        .attr("stroke", "red");

    const hirs = svg
        .append("g")
        .attr("id", "hirs-group")
        .attr("transform", `translate(${margin.left},${margin.right})`);

    /*
    hir.append('circle')
        .attr('cx', d => d.points[d.points.length - 1][0] * pitchMultiplier)
        .attr('cy', d => d.points[d.points.length - 1][1] * pitchMultiplier)
        .attr('r', d => 1 * pitchMultiplier)
        .style('stroke-width', lineWidth)
        .style('stroke', "#000")
        .style('fill', "#000")*/

    // create tooltip
    
    d3.select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

    return hirs;
};
const colors = d3
    .scaleLinear<string>()
    .domain(d3.ticks(5, 9, 11))
    .range([
        "#E6F598",
        "#FFFFBF",
        "#FEE08B",
        "#FDAE61",
        "#F46D43",
        "#D53E4F",
        "#9E0142",
    ]);

const removeChart = (
        svgRef: RefObject<SVGSVGElement>
    ) => {
        if (!svgRef.current) return;
    
        // const svg = d3
        //     .select(svgRef.current)
        
        d3.select(".tooltip")
            .remove();
    }

const drawData = (hirs_data: HIR[], onHirClick?: (hirId: string) => void, tooltipText?: (hir: HIR) => ReactElement) => {
    var tooltip = d3
        .select(".tooltip")
        .style("opacity", 0);

    const hirs = d3.select("#hirs-group");

    const hir_data = hirs
        .selectAll(".hir")
        .data<HIR>(hirs_data, (x: any) => x._id);

    hir_data.exit().remove();

    const hir_group = hir_data
        .enter()
        .append("g")
        .attr("class", "hir")
        .style("cursor", "pointer")
        .on("mouseover", function (this, e, d) {
            d3.select(this)
                .selectAll(".hir-path")
                .attr("stroke", "red")
                .attr("stroke-width", 2)
                .attr("marker-end", "url(#arrow-hover)");

            if (tooltipText) {
                tooltip.transition().duration(200).style("opacity", 0.9);
    
                ReactDOM.render(tooltipText(d), document.getElementsByClassName('tooltip')[0]);


                tooltip
                    .style("left", e.pageX + "px")
                    .style("top", e.pageY + "px");
            }

        })
        .on("mouseout", function (this, _, d) {
            d3.select(this)
                .selectAll(".hir-path")
                .attr("fill", "none")
                .attr("stroke", colors(d.speed_avg))
                .attr("stroke-width", 1)
                .attr("marker-end", `url(#arrow-${d._id})`);

            tooltipText && tooltip.transition().duration(500).style("opacity", 0);
        })
        .on("click", function (this, _, d) {
            onHirClick && onHirClick(d._id);
        });

    hir_group
        .append("path")
        .attr("class", "hir-path")
        .attr("d", (d) =>
            d3.line()([
                [
                    d.points[0][0] * pitchMultiplier,
                    d.points[0][1] * pitchMultiplier,
                ],
                [
                    d.points[d.points.length - 1][0] * pitchMultiplier,
                    d.points[d.points.length - 1][1] * pitchMultiplier,
                ],
            ])
        )
        .attr("stroke", (d) => colors(d.speed_avg))
        .attr("marker-end", (d) => `url(#arrow-${d._id})`)
        .attr("fill", (d) => colors(d.speed_avg));

    hir_group
        .append("path")
        .attr("class", "hir-select-helper")
        .attr("d", (d) =>
            d3.line()([
                [
                    d.points[0][0] * pitchMultiplier,
                    d.points[0][1] * pitchMultiplier,
                ],
                [
                    d.points[d.points.length - 1][0] * pitchMultiplier,
                    d.points[d.points.length - 1][1] * pitchMultiplier,
                ],
            ])
        )
        .attr("stroke", "transparent")
        .attr("fill", "none")
        .attr("stroke-width", 20);

    hir_group
        .append("marker")
        .attr("id", (d) => "arrow-" + d._id)
        .attr("viewBox", `0 0 ${markerBoxWidth} ${markerBoxHeight}`)
        .attr("refX", markerWidth)
        .attr("refY", markerHeight / 2)
        .attr("markerWidth", markerBoxWidth)
        .attr("markerHeight", markerBoxHeight)
        .attr("orient", "auto-start-reverse")
        .attr("fill", (d) => colors(d.speed_avg))
        .append("path")
        .attr("d", (d) =>
            d3.line()([
                [0, 0],
                [0, markerHeight],
                [markerWidth, markerHeight / 2],
            ])
        ) //'M 0,0 m -5,-5 L 5,0 L -5,5 Z')
        .attr("stroke", (d) => colors(d.speed_avg));

    hir_data.exit().remove();
};

type HIRViewProps = {
    hirs: HIR[];
    onHirClick?: (hirId: string) => void;
    tooltipText?: (hir: HIR) => ReactElement;
};

const HIRView: FunctionComponent<HIRViewProps> = ({hirs, onHirClick, tooltipText}) => {

    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        draw(svgRef);

        return () => {
            removeChart(svgRef);
        }
    }, []);

    useEffect(() => {
        if (!hirs) return;
        drawData(hirs, onHirClick, tooltipText);
    }, [hirs, onHirClick, tooltipText]);



    return (
        <svg id="hirs" ref={svgRef} />
    );
};

export default HIRView;
