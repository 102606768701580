import React, {
    FunctionComponent,
    MouseEvent,
    useEffect,
    useState,
} from "react";
import { MenuItem, NoSsr, Menu, Button, Tooltip, Hidden } from "@material-ui/core";
import useAxios from "axios-hooks";
import useClub from "sharedHooks/useClub";
import { Club } from "models/match";
import { FormattedMessage, useIntl } from "react-intl";
import {
    ExpandMore as ExpandMoreIcon,
    SportsSoccer as SportsSoccerIcon,
} from "@material-ui/icons";


const noClubSet = (currentClub: Club | null) => !currentClub;
const userBelongsToCurrentlySetClub = (currentClub: Club | null, allClubs: Club[]) => currentClub && allClubs.map(c => c._id).includes(currentClub._id);

const ClubMenu: FunctionComponent = () => {
    const [club, setClub] = useClub();

    const intl = useIntl();

    const [{ data: allClubs }] = useAxios<Club[]>("prostats/v1/clubs");

    useEffect(() => {
        if (!allClubs) return;

        if (noClubSet(club) || !userBelongsToCurrentlySetClub(club, allClubs)) {
            setClub(allClubs[0]);
        }
    }, [club, allClubs, setClub]);

    const [clubMenu, setClubMenu] = useState<
        | null
        | (EventTarget & HTMLAnchorElement)
        | (EventTarget & HTMLButtonElement)
    >(null);

    const handleClubMenuClick = (
        event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>
    ) => {
        setClubMenu(event.currentTarget);
    };

    const handleClubMenuClose = () => {
        setClubMenu(null);
    };

    const handleClubChange =
        (club: Club) =>
        (
            event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>
        ) => {
            setClub(club);
            setClubMenu(null);
        };

    return (
        <>
            {club && allClubs && allClubs.length > 1 ? (
                <>
                    <Tooltip
                        title={<FormattedMessage id="toolbar.changeClub" />}
                        enterDelay={300}
                    >
                        <Button
                            color="inherit"
                            aria-owns={clubMenu ? "locale-menu" : undefined}
                            aria-haspopup="true"
                            aria-label={intl.formatMessage({
                                id: "toolbar.changeLanguage",
                            })}
                            onClick={handleClubMenuClick}
                        >
                            <SportsSoccerIcon />
                            <Hidden smDown>
                                {club.name}
                            </Hidden>
                            <ExpandMoreIcon fontSize="small" />
                        </Button>
                    </Tooltip>
                    <NoSsr defer>
                        <Menu
                            id="language-menu"
                            anchorEl={clubMenu}
                            open={Boolean(clubMenu)}
                            onClose={handleClubMenuClose}
                        >
                            {allClubs.map((clubItem) => (
                                <MenuItem
                                    component="a"
                                    data-no-link="true"
                                    key={clubItem._id}
                                    selected={club === clubItem}
                                    onClick={handleClubChange(clubItem)}
                                >
                                    {clubItem.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </NoSsr>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default ClubMenu;
