import { createState, useState } from "@hookstate/core";
import { Persistence } from "@hookstate/persistence";
import { Club } from "models/match";
import { useDebugValue } from "react";
import { useHistory } from "react-router-dom";

type Hook = () => [Club | null, (club: Club) => void];

const stateLink = createState<Club | null>(null).attach(Persistence("club"));

const useClub: Hook = () => {
    const history = useHistory();

    const state = useState(stateLink);

    const setClub = (club: Club) => {
        state.set(club);
        history.push("/matches");
    };

    useDebugValue(state.get());

    return [state.get(), setClub];
};

export default useClub;
