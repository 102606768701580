import React, { FunctionComponent } from "react";
import "./ColorPatch.css";


type ColorProps = {
    color: string;
    inline?: boolean
};

const ColorPatch: FunctionComponent<ColorProps> = ({ color, inline = false }: ColorProps) => {
    const Container: React.ReactType = inline ? "span" : "div";
    return <Container className="color-patch" style={{backgroundColor: color}} />;
};

export default ColorPatch;
