import React, { FunctionComponent, useEffect } from "react";
import { Box, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { useMatch } from "../matchesHook";
import { useParams } from "react-router-dom";
import { useMatchResources } from "./matchResourcesHooks";
import { VideoPlayer } from "../hir/VideoPlayer";
import { useIntl } from "react-intl";
import ColorPatch from "sharedComponents/ColorPatch/ColorPatch";

type MatchResourcesParams = {
    matchId: string;
};

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const MatchResources: FunctionComponent = (props) => {
    const { matchId } = useParams<MatchResourcesParams>();

    const classes = useStyles();

    const [{ data: match }, loadMatch] = useMatch(matchId);

    const [ resources ] = useMatchResources(matchId);

    const intl = useIntl();

    useEffect(() => {
        loadMatch();
    }, [loadMatch]);

    const team_home = match ? match.team_home.name : "...";
    const team_away = match ? match.team_away.name : "...";
    const color_home = match ? match.team_home.color : "000000";
    const color_away = match ? match.team_away.color : "000000";

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Box p={1} textAlign="left">
                            <Typography variant="h4" align="left">
                            <span>{team_home} <ColorPatch color={color_home}/> - {team_away} <ColorPatch color={color_away}/></span>
                                <br />
                            </Typography>
                            <span>{intl.formatDate(match?.start_time)}</span>
                        </Box>
                    </Grid>
                </Grid>
                
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Box p={1} textAlign="left">
                        <Typography variant="h5" align="left">
                            Visualisierung Spaces-in-Between
                        </Typography>
                        {resources && <VideoPlayer url={resources.spaces_video} />}
                    </Box>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MatchResources;
