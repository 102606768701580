import React, { FunctionComponent } from 'react';
import clsx from "clsx";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import { keycloak, keycloakInitOptions } from "keycloak";
import { KeycloakProvider } from "@react-keycloak/web"
import Splash from "./Splash";

import { CssBaseline, makeStyles, createStyles, Theme } from '@material-ui/core';

import Matches from 'components/Matches/Matches';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';

import { IntlProvider } from "react-intl";
import useLocale, { Locale } from "sharedHooks/useLocale";
import de from "i18n/messages/de.json";
import en from "i18n/messages/en.json";
import useMenuOpen from "../../sharedHooks/useMenuOpen";
import MatchDetail from 'components/Matches/detail/MatchDetail';
import HIROverview from 'components/Matches/hir/HIROverview';
import HIRDetail from 'components/Matches/hir/HIRDetail';
import AddMatch from 'components/Matches/add/AddMatch';
import MatchResources from 'components/Matches/resources/MatchResources';

const messages: Map<Locale, any> = new Map([ ["de", de], ["en", en] ]);

const drawerHidden = 73,
    drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            content: {
                flexGrow: 1,
                padding: theme.spacing(3),
                marginLeft: `${drawerHidden}px`,
                marginTop: theme.mixins.toolbar.height,
                width: `calc(100% - ${drawerHidden}px)`,
            },
            contentShift: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(["width", "margin"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        }),
    );

const App: FunctionComponent = () => {
    const classes = useStyles();
    const [open] = useMenuOpen();
    const [locale] = useLocale();

    return (
        <IntlProvider locale={locale} messages={messages.get(locale)}>
            <KeycloakProvider
                keycloak={keycloak}
                initConfig={keycloakInitOptions}
                onTokens={(token) => {
                    if (process.env.NODE_ENV === "development") {
                        console.log("Token for testing: ");
                        console.log(token.token);
                    }
                }}
            >
                <Splash>
                    <Router>
                        <div className="App">
                            <CssBaseline />
                            <Header />

                            <Sidebar />

                            <main
                                className={clsx(classes.content, {
                                    [classes.contentShift]: open,
                                })}
                            >
                            
                                <div>
                                    {/* A <Switch> looks through its children <Route>s and
                                        renders the first one that matches the current URL. */}
                                    <Switch>
                                        <Route path="/matches/add">
                                            <AddMatch />
                                        </Route>
                                        <Route path="/matches/:matchId/resources">
                                            <MatchResources />
                                        </Route>
                                        <Route path="/matches/:matchId/:teamId/:player/hirs">
                                            <HIROverview />
                                        </Route>
                                        <Route path="/matches/:matchId">
                                            <MatchDetail />
                                        </Route>
                                        <Route path="/hir/:hirId">
                                            <HIRDetail />
                                        </Route>
                                        <Route path="/">
                                            <Matches />
                                        </Route>
                                    </Switch>
                                </div>
                            </main >
                        </div>
                    </Router>
                </Splash>
            </KeycloakProvider>
        </IntlProvider>
  );
}

export default App;

