import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Box, Card, FormControl, Grid, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { useMatch, useHirAggregation, usePlayerSquadMap } from "../matchesHook";
import { useIntl } from "react-intl";
import HirChart from "./HirChart";
import { useHistory, useParams } from "react-router-dom";
import ColorPatch from "sharedComponents/ColorPatch/ColorPatch";
import useSearchQuery from "sharedHooks/useSearchQuery";
import { isPhaseType } from "models/match";

type MatchDetailParams = {
    matchId: string;
};

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const MatchDetail: FunctionComponent = (props) => {
    const { matchId } = useParams<MatchDetailParams>();

    const {
        home: initialDisplayHome
    } = useSearchQuery(["home"]);

    const [home, setHome] = useState<boolean>(initialDisplayHome === undefined || initialDisplayHome === 'true' || initialDisplayHome === '1');
    const [{ data: match }, loadMatch] = useMatch(
        matchId
    );

    const [{ data: hirs }, loadHirs] = useHirAggregation(
        matchId,
        { home, phases: true }
    );

    const [playerMap] = usePlayerSquadMap(
        matchId,
        home
    );

    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();


    useEffect(() => {
        loadMatch();
        loadHirs();
    }, [loadMatch, loadHirs]);

    const team_home = match ? match.team_home.name : "...";
    const team_away = match ? match.team_away.name : "...";
    const color_home = match ? match.team_home.color : "000000";
    const color_away = match ? match.team_away.color : "000000";

    const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
        const home = !!event.target.value
        setHome(home);
        history.push(`/matches/${match._id}?home=${home}`);
    };

    const handleChartSegmentClick = useCallback((player, segment) => {
        if (!match) return;

        let q = ""
        if (isPhaseType(segment)) {
            q = "phase=" + segment
        } else {
            q = "direction=" + segment;
        }
        
        const teamId = home ? match.team_home._id : match.team_away._id
        history.push(`/matches/${match._id}/${teamId}/${player}/hirs?${q}`);
    }, [match, home, history])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Box p={1} textAlign="left">
                            <Typography variant="h4" align="left">
                            <span>{team_home} <ColorPatch color={color_home}/> - {team_away} <ColorPatch color={color_away}/></span>
                                <br />
                            </Typography>
                            <span>{intl.formatDate(match?.start_time)}</span>
                        </Box>
                    </Grid>
                </Grid>
                
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Box alignContent="left">
                        <FormControl className={classes.formControl}>
                            <Select
                                value={+home}
                                onChange={handleChange}
                                renderValue={(value) => {
                                    if (value === 1) {
                                        return <span>{team_home} <ColorPatch color={color_home}/></span>;
                                    } else {
                                        return <span>{team_away} <ColorPatch color={color_away}/></span>;
                                    }
                                }}
                                >
                                <MenuItem value={1} selected><span>{team_home} <ColorPatch color={color_home}/></span></MenuItem>
                                <MenuItem value={0}><span>{team_away} <ColorPatch color={color_away}/></span></MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {hirs && <HirChart
                                hirs={hirs}
                                playerMap={playerMap}
                                onChartSegmentClicked={handleChartSegmentClick} />}
                </Card>
            </Grid>
        </Grid>
    );
};

export default MatchDetail;
