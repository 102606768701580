import { Box, CircularProgress, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overlayContainer: {
            position: "relative"
        },
        overlay: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)"
        }
    }),
);

interface LoadingOverlayProps {
    loading: boolean;
}

const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = (props) => {
    const { loading, children } = props;

    const classes = useStyles();

    if (loading) {
        return (
            <Box className={classes.overlayContainer}>
                {children}
                <Box className={classes.overlay}>
                    <CircularProgress size="5rem" />
                </Box>
            </Box>
        );
    } else {
        return <>{children}</>;
    }
};

export default LoadingOverlay;