import React, { FunctionComponent } from "react";
import { Club, Player as PlayerType } from "models/match";
import { makeStyles } from "@material-ui/core";
import ColorPatch from "sharedComponents/ColorPatch/ColorPatch";
import Player from "./Player";

type PlayerProps = {
    player: PlayerType,
    club: Club
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
    },
    separator: {
        margin: "10px"
    }
}));

const PlayerWithClub : FunctionComponent<PlayerProps> = ({player, club}) => {
    const classes = useStyles();

    return <div className={classes.container}>
        <Player player={player} />
        <div className={classes.separator}>|</div>
        <div>
            {club.name}{" "}
            <ColorPatch color={club.color} />
        </div>
    </div>;
};

export default PlayerWithClub;