import "./HIRDetail.css";
import {
    Box,
    Button,
    Card,
    createStyles,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Theme,
    Typography,
    withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { round } from "sharedFunctions/utils";
import { useHir, usePlayer } from "../matchesHook";

import HIRView from "./HIRView";
import Player from "sharedComponents/Player";

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme) => ({
    minimapFlipped: {
        animation: "1s ease-out 0s 1 flip",
        transform: "rotateX(180deg) rotateY(180deg)"
    }
}));

const HIRDetail: FunctionComponent = () => {
    const { hirId } = useParams<{ hirId: string }>();
    const [{ data: hir }, loadHir] = useHir(hirId);

    const [playerObj] = usePlayer(hir?.match_id, hir?.team_id, hir?.player);

    const classes = useStyles();

    useEffect(() => {
        loadHir();
    }, [loadHir]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Card>
                    {hir && (
                        <Box component={Paper} p={2}>
                        <TableContainer>
                            <Table aria-label="customized table" size="small">
                                <TableBody>
                                    <StyledTableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            colSpan={2}
                                        >
                                            <Typography variant="h4">
                                                {playerObj ? <Player player={playerObj} /> : `Spieler ${hir.player}`}
                                            </Typography>
                                        </TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell component="th" scope="row">
                                            Minute
                                        </TableCell>
                                        <TableCell align="right">
                                        {round(hir.time / 60, 0)}:{round(hir.time % 60, 0).toString().padStart(2, '0')}
                                        </TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell component="th" scope="row">
                                            Richtung
                                        </TableCell>
                                        <TableCell align="right">
                                            {hir.direction}
                                        </TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell component="th" scope="row">
                                            Phase
                                        </TableCell>
                                        <TableCell align="right">
                                            {hir.phase}
                                        </TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell component="th" scope="row">
                                            Distanz
                                        </TableCell>
                                        <TableCell align="right">
                                            {round(hir.distance)} m
                                        </TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell component="th" scope="row">
                                            Geschwindigkeit
                                        </TableCell>
                                        <TableCell align="right">
                                            min {round(hir.speed_min * 3.6)} km/h{" "}
                                            <br /> avg {round(hir.speed_avg * 3.6)}{" "}
                                            km/h <br /> max{" "}
                                            {round(hir.speed_max * 3.6)} km/h
                                        </TableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={clsx({
                            [classes.minimapFlipped]: !hir.left_to_right,
                        })}>
                            <HIRView 
                                hirs={[hir]} />
                        </div>
                        </Box>
                    )}
                </Card>
            </Grid>
            <Grid item xs={12} md={9}>
                <Box component={Paper} p={2}>
                    No video available
                </Box>
            </Grid>
        </Grid>
    );
};

export default HIRDetail;
