import { useDebugValue } from "react";
import { createState, useState } from "@hookstate/core";
import { Persistence } from "@hookstate/persistence";

export type Locale = "en" | "de"
export const LOCALE_LABELS = [
  {
    code: 'en',
    text: 'English',
  },
  {
    code: 'de',
    text: 'Deutsch',
  },
];

type Hook = () => [Locale, (locale: Locale) => void];

const stateLink = createState<Locale>(
  (navigator.language.slice(0, 2) as Locale !== null)
    ? (navigator.language.slice(0, 2) as Locale)
    : "en"
).attach(Persistence('locale-key'));

const useLocale: Hook = () => {
  const state = useState(stateLink);

  const setLocale = (locale: Locale) => {
    state.set(locale);
  }

  useDebugValue(state.get());

  return [state.get(), setLocale]
}

export default useLocale;
