import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "components/App/App";
import * as serviceWorker from "./serviceWorker";

import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import client from "client";
import { configure } from "axios-hooks";

const theme = createMuiTheme({
    typography: {
        h1: {
            fontSize: '4rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '3rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 500,
        },
        h4: {
            fontSize: '1.2rem',
            fontWeight: 500,
        },
        h5: {
            fontSize: '1rem',
            fontWeight: 500,
        },
    },
    mixins: {
        toolbar: {
            height: '64px'
        }
    },
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#FF4A1C'
        }
    }
});

configure({axios: client});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
