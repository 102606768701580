import { DispatchWithoutAction, useDebugValue } from "react";
import { createState, useState } from "@hookstate/core";

type Hook = () => [boolean, DispatchWithoutAction];

const stateLink = createState(false);

const useMenuOpen: Hook = () => {
    const state = useState(stateLink);

    const toggleMenuOpen = () => {
        state.set(!state.get());
    };

    useDebugValue(state.get() ? "Open" : "Not Open");

    return [state.get(), toggleMenuOpen];
};

export default useMenuOpen;
