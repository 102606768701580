import axios from "axios";
import { keycloak } from "keycloak";

var axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE || "https://api.zone14.ai",
    /* other custom settings */
});

axiosInstance.interceptors.request.use((config) => {
    if (!keycloak.token) return config;

    config.headers = Object.assign(
        {
            Authorization: `Bearer ${keycloak.token}`
        },
        config.headers
    );
    return config
});

export default axiosInstance;
