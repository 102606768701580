import { useLocation } from "react-router-dom";

interface SearchQueries {
    [index: string]: string | undefined
}


const useSearchQuery = (params: string[]) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    
    const extractedSearchQueries : SearchQueries = {};
    params.forEach(k => {
        const v = query.get(k)
        if (v != null) {
            extractedSearchQueries[k] = v;
        }
    })

    return extractedSearchQueries;
};

export default useSearchQuery;
